import React from "react";
import Nav from "../components/Nav"

import Layout from "../components/layout";
import Seo from "../components/seo";

const TermsAndConditions = () => (
  <Layout>
    <Seo
      seo={{
        metaTitle: "Terms and conditions",
        metaDescription: "It looks like you got to the terms and conditions",
      }}
    />
    <Nav />
    <main>
      <h1>Terms and conditions</h1>
      <p>You just hit a route that doesn't exist... the sadness.</p>
    </main>
  </Layout>
);

export default TermsAndConditions;
